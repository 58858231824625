<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="file-title">
    <i class="iconfont fileanquanwangpan"></i>
    <h1>{{ name }}</h1>
  </div>
</template>

<script>
  export default {
    components: {

    },
    computed: {
      name() {
        return this.$store.state.system.name
      }
    },
    data() {
      return {
        
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped="" lang="scss">
  .file-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 230px;
    i{
      font-size: 30px;
      margin-right: 10px;
      color: #66788C;
      padding-top: 3px;
    }
    h1{
      font-size:1.5rem;
      font-family:Microsoft YaHei;
      font-weight:bold;
      color:rgba(102,120,140,1);
      letter-spacing: 0.1rem;
    }
  }
</style>
