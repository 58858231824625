<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="disk">
    <!-- 进度条组件 -->
    <el-progress
      :text-inside="true"
      :percentage="50"
      :stroke-width="6"
      status="exception"
    ></el-progress>
    <div class="tips">
      <div class="left">
        <span>2.4G/10G</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {

    },
    data() {
      return {
        
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped="" lang="scss">
  .disk{
    position: absolute;
    width: calc(100% - 72px);
    bottom: 80px;
    left: 0;
    padding: 0 36px;
    // 修改高度需要注释掉当前隐藏
    /deep/ .el-progress-bar__innerText{
      display: none;
    }
    .tips{
      padding-top: 10px;
      .left{
        span{
          font-size:14px;
          font-family:Microsoft YaHei;
          font-weight:400;
          color:rgba(102,120,140,1);
        }
      }
    }
  }
</style>
