<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="menu">
    <div class="menu-item" v-for="(menu, k) in data" :key="k">
      <router-link class="item" :to="menu.name">
        <i :class="menu.meta.icon"></i>
        <h2>{{ menu.meta.title }}</h2>
      </router-link>
      <ul class="list">
        <li v-for="(item, key) in menu.children" :key="key">
          <router-link :to="item.name" >
            <h3>{{ item.meta.title }}</h3>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { frameInRoutes } from '@/router/routes'
  export default {
    components: {
      
    },
    data() {
      return {
        data: frameInRoutes[0].children,
        active: ''
      }
    },
    watch: {
      '$route.matched': {
        handler (val) {
          this.active = val[val.length - 1].path
        },
        immediate: true
      }
    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped="" lang="scss">
  $hoverColor: #f7f7f7;
  .menu{
    padding-top: 1.25rem;
    cursor: pointer;
    .menu-item{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .item{
        width: 100%;
        padding: 1.31rem 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: 0.5s;
        &:hover{
          background-color: $hoverColor;
        }
        i{
          color: #4F5455;
          font-size: 1.2rem;
          padding: 0 1.25rem 0 2.31rem;
        }
        h2{
          font-size: 1.25rem;
          font-family:Microsoft YaHei;
          font-weight: 400;
          color: #66788C;
        }
      }
      .list{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        li{
          width: 100%;
          a{
            padding: 1.25rem 0 1.25rem 4.88rem;
            width: calc(100% - 4.88rem);
            display: block;
            transition: 0.5s;
            &:hover{
              background-color: $hoverColor;
            }
          }
          h3{
            font-size: 1.13rem;
            font-family:Microsoft YaHei;
            font-weight: 400;
            color:rgba(101,121,140,1);
          }
        }
      }
    }
    .router-link-active, .router-link-active:hover{
      background-color: #EFEFF3 !important;
    }
  }
</style>
