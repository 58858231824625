/*
 * @Descripttion: 文件路由集合
 * @version: 
 * @Author: luoxi
 * @Date: 2020年3月16日11:51:55
 * @LastEditors: luoxi
 * @LastEditTime: 2020年3月16日11:51:59
 */

import Layout from '@/layout'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/plugins/utils/import.' + process.env.NODE_ENV)

const meta = { auth: true }

const profix = process.env.VUE_APP_ROUTER_PROFIX

export default {
  path: '/' + profix,
  name: '/' + profix,
  meta: { ...meta, title: '全部文件' },
  redirect: { path: '/' + profix + '/home' },
  component: Layout,
  children: (pre => [
    { path: `${pre}/home`, name: `home`, component: _import('main/home'), meta: { ...meta, title: '全部文件', icon: 'iconfont filewenjian', type: 0 }, children: [
      { path: `${pre}/img`, name: `img`, component: _import('main/home'), meta: { ...meta, title: '图片', type: 1 } },
      { path: `${pre}/word`, name: `word`, component: _import('main/home'), meta: { ...meta, title: '文档', type: 4 } },
      { path: `${pre}/video`, name: `video`, component: _import('main/home'), meta: { ...meta, title: '视频', type: 2 } },
      { path: `${pre}/music`, name: `music`, component: _import('main/home'), meta: { ...meta, title: '音乐', type: 3 } },
      { path: `${pre}/other`, name: `other`, component: _import('main/home'), meta: { ...meta, title: '其他', type: 5 } }
    ] },
    { path: `${pre}/recovery`, name: `recovery`, component: _import('main/home'), meta: { ...meta, title: '回收站', icon: 'iconfont filehuishouzhan' } }
  ])('/' + profix)
}
