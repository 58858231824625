/*
 * @Descripttion: 首页
 * @version: 
 * @Author: luoxi
 * @Date: 2020年3月16日11:51:55
 * @LastEditors: luoxi
 * @LastEditTime: 2020年3月16日11:51:59
 */

const meta = { auth: true }

const profix = process.env.VUE_APP_ROUTER_PROFIX

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/plugins/utils/import.' + process.env.NODE_ENV)

export default {
  path: '',
  name: 'index',
  meta: { ...meta, title: '首页' },
  redirect: { path: '/' + profix + '/home' },
  children: [
    // 刷新页面 必须保留
    {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
    },
  ]
}
