<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="themeBx" :class="{ 'active': showThemeBx }" v-click-outside="closeThemeBx" v-show="!shows">
      <h2>系统主题</h2>
      <ul class="list">
        <li v-for="(item, key) in themes" :key="key">
          <div class="box" :style="{ 'background': item.color }" @click="themeChange(item)">
            <div class="select" :class="{ 'choose': activeTheme === item.value }">
              <i class="iconfont fileicon-test"></i>
            </div>
          </div>
          <h3>{{ item.name }}</h3>
        </li>
      </ul>
     
    </div>
</template>

<script>
  export default {
      inject:["reload"],
    components: {

    },
    computed:{
      showThemeBx() {
        return this.$store.state.theme.showThemeBx
      }
    },
    data() {
      return {
        themes: [
          { name: '默认主题', color: '#EFEFF3', value: 'day' },
          { name: '深夜主题', color: '#404868', value: 'night' }
        ],
        activeTheme: 'day',
        shows:false
      }
    },
    watch: {
      activeTheme(newVal) {
        this.$store.commit('theme/themeChange', newVal)
      }
    },
    created() {

    },
    mounted() {
    },
    methods: {
      // 主题修改
      themeChange(row) {
        this.activeTheme = row.value
      },
      // 关闭主题面板
      closeThemeBx() {
       // if (this.showThemeBx) {
       //  this.$store.commit('theme/themeMaskChange', false)
       // }
      },
      
     
    }
  }
</script>

<style scoped="" lang="scss">
    .themeBx{
      width: 230px;
      height: calc(100% - 84px);
      background:rgba(254,254,255,1);
      box-shadow: 0px 4px 6px 0px rgba(43, 43, 43, 0.2);
      position: fixed;
      top: 84px;
      right: -230px;
      z-index: 10;
      transition: 0.2s;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      &.active{
        right: 0;
      }
      h2{
        font-size:20px;
        font-family:Microsoft YaHei;
        font-weight:bold;
        color:rgba(102,120,140,1);
        padding-top: 30px;
      }
      .list{
        width: calc(100% - 48px);
        margin-top: 40px;
        padding: 0 24px;
        li{
          width: 100%;
          margin-bottom: 30px;
          .box{
            height:80px;
            width: 100%;
            border-radius:6px;
            margin-bottom: 15px;
            position: relative;
            cursor: pointer;
            .select{
              position: absolute;
              top: 8px;
              right: 8px;
              width:20px;
              height:20px;
              opacity: 0;
              border-radius:50%;
              display: flex;
              align-items: center;
              justify-content: center;
              &.choose{
                background:rgba(52,137,254,1);
                color: #fff;
                opacity: 1;
                i{
                  font-size: 14px;
                }
              }
            }
          }
          h3{
            font-size: 17px;
            font-family:Microsoft YaHei;
            font-weight:400;
            color:rgba(101,121,140,1);
          }
        }
      }
      
    }
</style>
