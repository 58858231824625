/*
 * @Descripttion: 快速获取数据的方法
 * @version: 
 * @Author: luoxi
 * @Date: 2020年3月16日11:1:23
 * @LastEditors: luoxi
 * @LastEditTime: 2020年3月16日11:1:27
 */
const getters = {
    // currentSite: state => state.site.currentSite
}
export default getters