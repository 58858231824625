/*
 * @Description: 常用过滤器库
 * @Author: Liyuanpeng
 * @Date: 2019-07-22 14:38:51
 * @LastEditTime: 2019-07-22 15:10:13
 * @LastEditors: Liyuanpeng
 */

import dayjs from 'dayjs'
import Vue from 'vue'
/**
 * @description: 四舍五入
 * @param : value,decimal
 * @return: data
 */
export function round_money(value,decimal = 2){
    let p = Math.pow(10,decimal)
    return Math.round(value * p) / p
}
/**
 * @description: 向下取整
 * @param : value,decimal
 * @return: data
 */
export function floor_money(value, decimal = 2) {
    let p = Math.pow(10, decimal)
    return Math.floor(value * p) / p
}
/**
 * @description: 向上取整
 * @param : value , decimal
 * @return: data
 */
export function ceil_money(value, decimal = 2) {
    let p = Math.pow(10, decimal)
    return Math.ceil(value * p) / p
}
/**
 * @description: 舍弃多余部分
 * @param : value , len
 * @return: data
 */
export function more(value, len = 10) {
    return value.toString().substr(0, len) + '...'
}
/**
 * @description: 格式化成日期
 * @param : value,format 
 * @return: data
 */
export function datetime( value, format = "YYYY-MM-DD HH:mm:ss" ) {
    return dayjs(value).format(format)
}

/**
 * @description: 部分隐藏电话号码
 * @param : value 
 * @return: data
 */
export function toTel( value ) {
    let start = value.slice(0, 3)
    let end = value.slice(-4)
    return `${start}****${end}`
}
/**
 * @description: 文件计算
 * @param : value 
 * @return: data
 */
export function mediaSize( value ) {
    let units = ['k','M','G']
    let Tap = 0
    return eachVal(Math.ceil(value / 1024))
    function eachVal(vals){
      if(vals<1024) return vals.toString() + units[Tap]
      else {
        Tap += 1
        return eachVal(Math.ceil(vals / 1024))
      }
    } 
}
/**
 * @description: 资源过滤器
 * @param : value 
 * @return: data
 */
export function mediaSrc( value ) {
    //return Vue.prototype.$imgprofix + val;
    return Vue.prototype.$imgprofix + value
}
/**
 * @description: 
 * @param : value 
 * @return: data
 */
export function ToString( value ) {
    return value===null?'': value.toString()
}
/**
 * @description: 字符串超出省略
 * @param : value , length 
 * @return: data 
 */
export function ellipsis (value, length) {
    let lengthNum = length? length: 20
    if (!value) return ''
    if (value.length > lengthNum) {
        return value.slice(0,lengthNum) + '...'
    }
    return value
}