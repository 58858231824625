<!--
 * @Descripttion: 公共头部侧边栏入口文件
 * @version: 0.1
 * @Author: luoxi
 * @Date: 2020年3月16日16:47:50
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="header-side" >
    <Header />
    <div class="container">
      <Side />
      <Theme />
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from './header'
  import Side from './side'
  import Theme from './header/theme'
  export default {
    components: {
      Header,
      Side,
      Theme
    },
    data() {
      return {
        
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
    }
  }
</script>

<style scoped="" lang="scss">
  .header-side{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .container{
      display: flex;
      flex-wrap: wrap;
      background-color: #F1EEF4;
      height: calc(100% - 84px);
      .content{ 
        flex: 1;
        padding: 19px;
        width: calc(100% - 38px);
        height: calc(100% - 38px);
        overflow: hidden;
      }
    }
    
  }
</style>
