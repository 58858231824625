import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from '@/plugins/utils'
import VueLazyload from 'vue-lazyload'
// 自定义指令插件
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

// 导入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/init.scss'
import '@/assets/scss/commons.scss'
import '@/assets/scss/element.scss'
Vue.use(ElementUI)

// 加载过滤器
import * as filters from '@/plugins/filter'
Object.keys(filters).forEach(key=>{
  Vue.filter(key,filters[key])
})

Vue.component('globalUploader',() => import('@/components/global-uploader'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 路由较验
    // if (process.env.NODE_ENV === 'development') {
    if (process.env.NODE_ENV) {
      utils.cookies.set('token', 'Aa5LI965zez+7XbieioNNh5lu7D7QNbpDSj+TUQ7+rbXrXhJ1Kfu7DxOKix5B36Tlnz/JS7Ioq28XvZ40WAk4WwmwyXu52Y1xMM7Uf7O4flTgbSODkXizoB9xGShKNVdpXwUtn8q3C70NwuYRwsaWkNXdow9qbsAjPL7FaIEQQTUTgHcnFbY6kAQ1KWOx55VTbTI4bwadoK6Yum3KlHOYg==')
    }
    // 判断有无token, 无token直接跳转至登录路由
    if (!utils.cookies.get('token')) {
      this.jumpLogin()
    }
  },
  mounted() {

  },
  methods: {
    // 跳转至登录路由
    jumpLogin() {
      this.$router.push({
        name: 'login'
      })
    }
  }
}).$mount('#app')
