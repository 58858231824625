export default {
  namespaced: true,
  state: {
    theme: '',
    showThemeBx: false
  },
  mutations: {
    themeChange(state, theme) {
      state.theme = theme
    },
    themeMaskChange(state, value) {
      state.showThemeBx = value
    }
  }
}