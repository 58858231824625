export default {
  namespaced: true,
  state: {
    input: ''
  },
  mutations: {
    inputChange(state, input) {
      state.input = input
    }
  }
}