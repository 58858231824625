<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="header">
    <Title />
    <Search />
    <User />
  </div>
</template>

<script>
  import Title from './title'
  import Search from './search'
  import User from './user'
  export default {
    components: {
      Title,
      Search,
      User
    },
    data() {
      return {
        
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>
<style scoped="" lang="scss">
  .header{
    background-color: #fff;
    height:84px;
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow:0px 0px 6px 0px rgba(43,43,43,0.24);
  }
</style>
