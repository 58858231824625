<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="user">
    <div class="thumb" style="background-image: url('http://117.187.131.92:8088/passport/v1/img/ee23e5501f9184325958e7bf2e2a19b5');"></div>
    <div class="info">
      <span class="name">用户名</span>
      <span class="id">zhanghao</span>
    </div>
    <button class="theme" title="切换主题" @click="changeThemeShow">
      <i class="iconfont filezhuti1"></i>
    </button>
    <button class="close" title="退出登录">
      <i class="iconfont filezhuxiao"></i>
    </button>
     <div class="body-box" @click="closes" v-if='showThemeBx'></div>
  </div>
</template>

<script>
  export default {
    components: {

    },
    data() {
      return {
        showThemeBx: false
      }
    },
    watch: {
      showThemeBx(newVal) {
        this.$store.commit('theme/themeMaskChange', newVal)
      }
    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      // 改变主题显隐
      changeThemeShow() {
        this.showThemeBx = !this.showThemeBx
        window.sessionStorage.setItem('tabFlag',this.showThemeBx)
      },
      closes(e){
          this.showThemeBx=false
      }
      
    }
  }
</script>

<style scoped="" lang="scss">
  .user{
    margin-right: 3.5rem;
    display: flex;
    align-items: center;
    z-index: 1000;
    height: 100%;
    .thumb{
      width:3.13rem;
      height:2.94rem;
      border-radius:1rem;
      background: #000;
      margin-right: 0.88rem;
    }
    .info{
      display: flex;
      flex-direction: column;
      .name{
        font-size:1rem;
        font-family:Microsoft YaHei;
        font-weight:bold;
        color:rgba(68,68,68,1);
      }
      .id{
        font-size:0.88rem;
        font-family:Microsoft YaHei;
        font-weight:400;
        color:rgba(136,136,136,1);       
      }
    }
    .theme, .close{
      width:48px;
      height:48px;
      background:rgba(185,203,215,1);
      border-radius:50%;
      margin-left: 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      i{
        font-size: 20px;
        color: #fff;  
      }
    }
    .close{
      svg{
        width: 23px;
        height: 23px;
      }
    }
  }
  .body-box{
     position: fixed;
     top: 0px;
     left: 0px;
     width: 83%;
     height: 100%;
     
  }
</style>
