<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="side">
    <Menu />
    <Disk />
  </div>
</template>

<script>
  import Menu from './menu'
  import Disk from './disk'
  export default {
    components: {
      Menu,
      Disk
    },
    data() {
      return {
        
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped="" lang="scss">
  .side{
    width:230px;
    background:rgba(254,254,255,1);
    box-shadow:0px 0px 6px 0px rgba(43,43,43,0.05);
    position: relative;
    .radio{
      position: absolute;
      bottom: 80px;
      width: 100%;
      text-align: center;
    }
  }
</style>
