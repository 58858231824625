/*
 * @Description: 路由管理页面
 * @Author: luoxi
 * @Date: 2020年3月16日10:46:49
 * @LastEditTime: 2020年3月16日10:45:5
 * @LastEditors: luoxi
 */

import Index from './modules/index'
import File from './modules/file'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/plugins/utils/import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
  File
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  Index,
  {
    path: '/login',
    name: 'login',
    component: _import('system/login/')
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: _import('system/error/404'),
    meta: { title: '404页面' }
  }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...errorPage
]