import cookies from '@/plugins/utils/cookies'

    // 把地址数据fileNav加载至cookie中
    function joinNav2Cookie(nav) {
        let string = JSON.stringify(nav)
        cookies.set('nav', string)
    }
    // 从 cookie中加载 fileNav
    function getNav2cookie() {
        let string = cookies.get('nav')
        if (string)
            return JSON.parse(string)
    }
    // 新增路由子目录
    function fileNavAddPath(row) {
        this.fileNav.push(row)
    }

export default {
    namespaced: true,
    state: {
        nav: getNav2cookie() || [{ name: '全部文件' }] // 文件路径
    },
    mutations: {
        navPush(state, row) {
            state.nav.push(row)
            joinNav2Cookie(state.nav)
        },
        // 点击面包屑导航时修改文件路径
        navChange(state, item) {
            for (const i in state.nav) {
                if (state.nav[i] === item) {
                    state.nav = state.nav.slice(0, parseInt(i) + 1)
                    break
                }
            }
            joinNav2Cookie(state.nav)
        },
    }
}