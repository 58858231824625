<template>
    <div id="app" :class="theme">
        <router-view v-if="isRouterActive" />
    </div>
</template>

<script>
    export default {
        name: 'app',
        provide() {
            return {
                reload: this.reload
            }
        },
        computed: {
            theme() {
                return this.$store.state.theme.theme
            }
        },
        data() {
            return {
                isRouterActive: true
            }
        },
        methods: {
            //刷新组件方法，需要的地方直接调这个方法，需在所需页面加“inject:["reload"],”,
            reload() {
                this.isRouterActive = false
                this.$nextTick(function() {
                    this.isRouterActive = true
                })
            }
        }
    }
</script>
