<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="search">
    <div class="center"
      @click="active = true"
      :class="{ 'active': active }"
      v-click-outside=" () => active = false"
      @keyup.enter="search"
    >
      <i class="iconfont filesousuo"></i>
      <el-input v-model="input" placeholder="搜索您的文件"></el-input>
      <label @click="search">搜索</label>
    </div>
  </div>
</template>

<script>
  export default {
    components: {

    },
    data() {
      return {
        input: '',
        active: false
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      
    },
    methods: {
      // 搜索功能
      search() {
        this.$store.commit('header/inputChange', this.input)
      }
    }
  }
</script>

<style scoped="" lang="scss">
  .search{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .center{
      position: relative;
      &.active{
        .el-input{
          /deep/ .el-input__inner{
            border-color: #409EFF;
          }
        }
        label{
          border-color: #409EFF;
        }
      }
      .el-input{
        width: 29.94rem;
        /deep/ .el-input__inner{
          height: 2.7rem;
          line-height: 2.7rem;
          border: 1px solid #DDDDDD;
          border-radius: 1rem;
          padding-left: 2.9rem;
        }   
      }
      label{
        padding: 0.7rem 1.2rem;
        font-size: 1rem;
        font-family:Microsoft YaHei;
        border-radius:0 1rem 1rem 0;
        border-left: 1px solid #DDDDDD;
        color: #65798C;
        cursor: pointer;
        letter-spacing: 0.2rem;
        user-select: none;
        position: absolute;
        right: 0;
        &:hover{
          color: #3489FE;
          font-weight: bold;
        }
      }
      i{
        position: absolute;
        z-index: 10;
        top: 0.9rem;
        left: 1.1rem;
        color: #B9CBD7;
        user-select: none;
      }
    }
  }
</style>
