/*
 * @Descripttion: store主文件，调用modules里面的文件进行数据存储
 * @version: 
 * @Author: luoxi
 * @Date: 2019-09-18 11:34:04
 * @LastEditors: seven
 * @LastEditTime: 2019-09-18 11:34:04
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// 正则导入modules里面的所有文件
const modulesFiles = require.context('./modules', false, /\.js$/)

// 遍历文件获取文件名称
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  modules,
  getters
})
